<template>
    <fragment>
        <div
            class="d-flex flex-wrap flex-md-row flex-column-reverse align-items-end w-100"
        >
            <div class="col-12 col-md-9 p-md-0 mx-auto">
                <div class="w-100 d-flex align-items-end">
                    <div
                        v-click-outside="hide"
                        class="w-100 position-md-relative"
                    >
                        <vs-input
                            id="search-items"
                            ref="inventorySearchScanner"
                            v-model="keySearch"
                            autocomplete="off"
                            border
                            shadow
                            type="text"
                            name="product-name"
                            placeholder="Buscador de productos"
                            class="w-100"
                            :label="label"
                            :disabled="disabledInput || !enabled"
                            @input="onInput"
                            @focus="emitFocus"
                            @blur="onBlur"
                            @click-icon="requiredSearch"
                            @keyup.esc="focus = -1"
                            @keyup.enter="onEnterOnInput"
                            @keyup.down="incrementFocus"
                            @keyup.up="decrementFocus"
                        >
                            <template #icon> 🔎 </template>
                        </vs-input>
                        <div
                            v-if="show"
                            class="position-absolute bg-base z-40 px-4 mt-1 top-4 inset-x-0 md:left-auto origin-top-right md:origin-top"
                        >
                            <div
                                class="w-100 rounded pb-2 border-b border-right border-left border-secondary"
                            >
                                <div class="overflow-auto h-max-96 w-40">
                                    <div
                                        v-for="(item, i) in items"
                                        :key="i"
                                        class="w-100 p-0"
                                        :class="{ 'form-control': focus === i }"
                                    >
                                        <button
                                            type="button"
                                            class="w-100 btn text-success rounded-0 mb-2"
                                            @click="clickButton(i)"
                                        >
                                            {{ item.name | cut(50) }} |
                                            {{ item.priceOffer | money }}
                                        </button>
                                    </div>
                                    <div
                                        v-if="isLoading"
                                        class="w-100 d-flex align-items-center justify-content-center"
                                    >
                                        Cargando...
                                    </div>

                                    <div
                                        v-if="!items.length && !isLoading"
                                        class="py-4 px-2 d-flex justify-content-center"
                                    >
                                        <span class="font-weight-bold text-md">
                                            Nuestro sistema no encuentra
                                            resultados 😢
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <slot name="complement"></slot>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div
                    class="w-100 d-flex justify-content-center flex-lg-row flex-column-reverse"
                >
                    <barcode-scanner
                        v-model="isEnabledScanner"
                        :enabled="enabled"
                        @input="searchByBarcode"
                    />
                </div>
            </div>
        </div>

        <vs-dialog
            v-model="openModalReferences"
            scroll
            overflow-hidden
            auto-width
            @close="isEnabledScanner = true"
        >
            <template #header>
                <div
                    class="mx-4 mt-2 d-flex flex-column flex-lg-row align-items-center"
                >
                    <span class="h4">
                        Seleccione la referencia del producto
                        <span class="text-primary">
                            {{ productTmp.name }}
                        </span>
                    </span>
                </div>
            </template>
            <div class="con-content">
                <div class="d-flex flex-wrap w-100">
                    <div
                        v-for="(item, index) in productTmp.references"
                        :key="item.id"
                        class="col-10 col-md-5 col-lg-3 mx-auto p-2"
                    >
                        <button
                            class="d-flex flex-column btn btn-primary w-100"
                            :class="
                                referenceSelected === index
                                    ? 'border-3 border-highlight'
                                    : ''
                            "
                            @click="selectReference(index)"
                        >
                            <div>
                                <strong> Tamaño </strong>
                                <span class="text-capitalize">
                                    {{ item.size }}
                                </span>
                            </div>
                            <div>
                                <strong> Color </strong>
                                <span>
                                    {{ item.color }}
                                </span>
                            </div>
                            <div>
                                <strong> Precio </strong>
                                <span>
                                    {{ item.priceOffer | money }}
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </vs-dialog>
    </fragment>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions, mapGetters, mapState } from "vuex";

import BarcodeScanner from "@/components/utils/BarcodeScanner.vue";

export default {
    name: "InventoriesSearchScanner",
    directives: {
        ClickOutside
    },
    components: { BarcodeScanner },
    model: {
        prop: "itemCurrent",
        event: "input"
    },
    props: {
        defaultFilters: {
            default: () => ({
                active: true
            }),
            type: Object,
            required: false
        },
        itemCurrent: {
            default: () => ({ name: "" }),
            type: Object,
            required: false
        },
        label: {
            default: () => "",
            type: String,
            required: false
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        },
        enabledScanner: {
            default: () => true,
            type: Boolean,
            required: false
        },
        autoFocus: {
            default: () => false,
            type: Boolean,
            required: false
        }
    },
    emits: ["blur", "focus", "input"],
    data: () => ({
        keySearch: "",
        isLoading: false,
        show: false,
        disabledInput: false,
        isEnabledScanner: true,
        focus: -1,
        selectedKey: "",
        items: [],
        openModalReferences: false,
        referenceSelected: null,
        productTmp: {
            id: 0,
            sku: "",
            slug: "",
            name: "",
            references: []
        }
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {
        itemCurrent(value) {
            this.keySearch = `${value.name}`;
            this.selectedKey = `${value.name}`;
        },
        enabledScanner(value) {
            this.setEnabledScanner(value);
        }
    },
    mounted() {
        this.setEnabledScanner(this.enabledScanner && !this.autoFocus);
        this.keySearch = `${this.itemCurrent.name}`;
        this.selectedKey = `${this.itemCurrent.name}`;
    },
    methods: {
        ...mapActions("inventories", ["searchInventories"]),
        requiredSearch() {
            this.debounce(this.advanceSearch, 0, this.keySearch, true);
        },
        async searchByBarcode(barcode) {
            try {
                const itemsAux = await this.searchInventories({
                    ...this.defaultFilters,
                    merchantType: this.selectedMerchant.merchantType,
                    merchantId: this.selectedMerchant.merchantId,
                    barcode
                });
                if (itemsAux.length > 0) {
                    this.selectProduct(itemsAux[0]);
                }
                this.show = false;
                this.focus = -1;
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async advanceSearch(keyword, required = false) {
            if (!keyword) return;
            this.isLoading = true;
            try {
                this.items = await this.searchInventories({
                    ...this.defaultFilters,
                    merchantType: this.selectedMerchant.merchantType,
                    merchantId: this.selectedMerchant.merchantId,
                    keyword,
                    required
                });

                this.focus = -1;
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async onInput() {
            this.isEnabledScanner = false;
            this.show = !!this.keySearch;
            this.debounce(this.advanceSearch, 500, this.keySearch, false);
        },
        debounce(func, time, ...params) {
            if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                func(...params);
            }, time);
        },
        onBlur() {
            this.$emit("blur");
        },
        emitFocus() {
            this.isEnabledScanner = true;
            if (this.selectedKey === this.keySearch) {
                this.keySearch = "";
            }
            this.$emit("focus");
        },
        selectItem() {
            const item = this.items[this.focus];
            this.selectProduct(item);
            this.selectedKey = `${item.name}`;
            this.focus = -1;
            this.show = false;
            this.disabledInput = true;
            setTimeout(() => {
                this.disabledInput = false;
            }, 300);
        },
        hide() {
            this.keySearch = `${this.selectedKey}`;
            this.show = false;
            this.focus = -1;
        },
        onEnterOnInput() {
            if (this.focus > -1) {
                this.selectItem();
                return;
            }
            this.requiredSearch();
        },
        clickButton(index) {
            this.focus = index;
            this.selectItem();
        },
        incrementFocus() {
            if (this.focus === -1) {
                this.focus = 0;
            } else if (this.focus < this.items.length - 1) {
                this.focus = this.focus + 1;
            }
        },
        decrementFocus() {
            if (this.focus === -1) {
                this.focus = 0;
            } else if (this.focus > 0) {
                this.focus = this.focus - 1;
            }
        },
        setEnabledScanner(value) {
            this.isEnabledScanner = Boolean(value);
        },
        async selectProduct(product) {
            this.productTmp = { ...product };
            if (product.references.length === 0) {
                await this.$swal.fire({
                    icon: "error",
                    title: "Producto invalido",
                    text: "Entra a los detalles del producto y verifica que tenga referencias"
                });
                window
                    .open(`/app/products/detail/${product.id}`, "_blank")
                    .focus();
            } else if (product.references.length === 1 || this.any) {
                this.validatePush(product.references[0], product);
                this.openModalReferences = false;
                this.referenceSelected = -1;
                this.isEnabledScanner = true;
                this.removeHandlerKey();
            } else {
                this.isEnabledScanner = false;
                this.productTmp = { ...product };
                this.referenceSelected = -1;
                this.openModalReferences = true;
                this.addHandlerKey();
            }
        },
        validatePush(reference, product) {
            this.$emit("input", {
                ...reference,
                appliedDiscountPerReference: 0,
                product: {
                    id: product.id,
                    sku: product.sku,
                    slug: product.slug,
                    name: product.name
                }
            });
            this.referenceSelected = -1;
        },
        pushReference() {
            if (this.referenceSelected < 0) return;
            const reference =
                this.productTmp.references[this.referenceSelected];
            this.validatePush(reference, this.productTmp);
            this.removeHandlerKey();
            this.openModalReferences = false;
            this.referenceSelected = -1;
            this.isEnabledScanner = true;
        },
        selectReference(index) {
            if (index < 0) {
                return;
            }
            this.isEnabledScanner = false;
            this.referenceSelected = index;
            this.pushReference();
        },
        incrementReferenceSelect() {
            this.isEnabledScanner = false;
            if (!this.openModalReferences) return;
            if (this.referenceSelected < 0) {
                this.referenceSelected = 0;
            } else if (
                this.referenceSelected <
                this.productTmp.references.length - 1
            ) {
                this.referenceSelected = this.referenceSelected + 1;
            }
        },
        decrementReferenceSelect() {
            this.isEnabledScanner = false;
            if (!this.openModalReferences) return;
            if (this.referenceSelected < 0) {
                this.referenceSelected = 0;
            } else if (this.referenceSelected > 0) {
                this.referenceSelected = this.referenceSelected - 1;
            }
        },

        handleKey(event) {
            const actions = {
                Enter: this.pushReference,
                Escape: this.closeReferenceModal,
                ArrowUp: this.decrementReferenceSelect,
                ArrowDown: this.incrementReferenceSelect
            };
            const fun = actions[event.key] || function () {};
            fun();
        },
        closeReferenceModal() {
            this.openModalReferences = false;
            this.isEnabledScanner = true;
        },
        removeHandlerKey() {
            document.removeEventListener("keydown", this.handleKey);
        },
        addHandlerKey() {
            document.addEventListener("keydown", this.handleKey);
        },
        onBlurSomeInput() {
            this.isEnabledScanner = true;
            this.addHandlerKey();
        },
        onFocusSomeInput() {
            this.isEnabledScanner = false;
            this.removeHandlerKey();
        }
    }
};
</script>
